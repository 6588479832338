import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import Select from 'react-select';
import { motion, AnimatePresence } from 'framer-motion';
import Popup from 'reactjs-popup';

import Modal from './Modal';
import global from './Global';
import {
    db,
    getPath,
    sortData,
    msg,
    name,
    ext,
    download,
    format,
    formatBytes,
    lastPart,
    cleanSharePath,
    noAccess,
    encrypt,
    fileIcon,
} from '../js/functions';
import { action, selection, update } from '../js/helper';

//############################################################################# Options

const { fileTypes, fileTypesLabel } = global;

const filterOptions = Object.keys(fileTypes).map(x => ({ label: fileTypesLabel[x], value: x }));

const sortingOptions = [
    { label: 'Name', value: 'name' },
    { label: 'Datum', value: 'date' },
    { label: 'Größe', value: 'size' },
];

//#############################################################################

class Action extends Component {
    localstate = observable({
        shareLink: '',
        shareAccessInfo: null,
        shareExists: false,
        form: {
            folderName: '',
            noteName: '',
            rename: '',
            shareUsers: [],
        },
        details: null,
    });

    async createFolder() {
        if (noAccess('createFolder')) return;
        const { folders } = global;
        const { form } = this.localstate;

        if (!form.folderName) {
            msg('Gib einen Ordername ein', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.folderName === 'thumbnail' || form.folderName === 'shared') {
            msg('Dieser Name ist leider reserviert', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.folderName.match(/[#/\\?<>|*:“%`´]/)) {
            msg('Folgende Sonderzeichen nicht erlaubt', '# / \\ ? < > | * : “ %  ` ´', 'fal fa-exclamation-circle');
            return;
        }
        if (folders.map(x => x.name).includes(form.folderName)) {
            msg('Ordner mit gleichen Namen existiert bereits', null, 'fal fa-exclamation-circle');
            return;
        }

        await action('createFolder', {
            name: form.folderName,
        });

        update('createFolder');

        global.modal = null;
        msg('Ordner erstellt', `„${form.folderName}“`, 'fal fa-check');
        this.localstate.form.folderName = '';
    }

    async createNote() {
        if (noAccess('createNote')) return;
        const { files } = global;
        const { form } = this.localstate;

        if (!form.noteName) {
            msg('Gib einen Notizname ein', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.noteName.match(/[#/\\?<>|*:“%`´]/)) {
            msg('Folgende Sonderzeichen nicht erlaubt', '# / \\ ? < > | * : “ %  ` ´', 'fal fa-exclamation-circle');
            return;
        }
        if (files.map(x => x.name).includes(`${form.noteName}.txt`)) {
            msg('Notiz mit gleichen Namen existiert bereits', null, 'fal fa-exclamation-circle');
            return;
        }

        await action('createNote', {
            name: form.noteName,
        });

        update('createNote');

        global.modal = null;
        msg('Notiz erstellt', `„${form.noteName}“`, 'fal fa-check');
        this.localstate.form.noteName = '';
    }

    async download() {
        if (noAccess('download')) return;
        const { path, user, sharedFolder, selected } = global;

        if (selected.folders.length > 0 && selected.files.length === 0) {
            msg('Es können nur Dateien heruntergeladen werden', null, 'fal fa-exclamation-circle');
            return;
        }

        for (const itemName of selected.files) {
            let src = `${path}${getPath()}${itemName}`;

            if (sharedFolder) {
                src = user ? src.replace(`${user?.name}/shared/`, '') : src.replace('shared/', '');
            }

            download(src);
        }

        msg(
            `${selected.files.length === 1 ? `„${selected.files[0]}“` : 'Elemente'} heruntergeladen`,
            selected.folders.length > 0 ? 'Es werden nur Dateien heruntergeladen' : null,
            'fal fa-check'
        );
        selection([], true);
    }

    async rename() {
        if (noAccess('rename')) return;
        const { folders, files, selected } = global;
        const { form } = this.localstate;
        const selectedItems = [...selected.folders, ...selected.files];
        const isFile = selected.files.includes(selectedItems[0]);
        const baseName = isFile ? name(selectedItems[0]) : selectedItems[0];
        const fullName = isFile ? `${form.rename}.${ext(selectedItems[0])}` : form.rename;

        if (!form.rename) {
            msg('Gib einen neuen Namen ein', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.rename === 'thumbnail' || form.rename === 'shared') {
            msg('Dieser Name ist leider reserviert', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.rename === baseName) {
            msg('Neuer Name identisch mit dem alten', null, 'fal fa-exclamation-circle');
            return;
        }
        if (form.rename.match(/[#/\\?<>|*:“%`´]/)) {
            msg('Folgende Sonderzeichen nicht erlaubt', '# / \\ ? < > | * : “ % ` ´', 'fal fa-exclamation-circle');
            return;
        }
        if (ext(selectedItems[0])) {
            if (files.map(x => x.name).includes(`${form.rename}${ext(selectedItems[0], false)}`)) {
                msg('Datei mit gleichen Namen existiert bereits', null, 'fal fa-exclamation-circle');
                return;
            }
        } else {
            if (folders.map(x => x.name).includes(form.rename)) {
                msg('Ordner mit gleichen Namen existiert bereits', null, 'fal fa-exclamation-circle');
                return;
            }
        }

        await action('rename', {
            src: selectedItems[0],
            dest: fullName,
        });

        update('rename');

        global.modal = null;
        this.localstate.form.rename = '';
        msg('Umbenannt', `„${selectedItems[0]}“ zu „${fullName}“`, 'fal fa-check');
        selection([], true);
    }

    async delete() {
        if (noAccess('delete')) return;
        const { selected } = global;
        const selectedItems = [...selected.folders, ...selected.files];

        await action('delete', {
            folders: selected.folders,
            files: selected.files,
        });

        update('delete');

        global.modal = null;
        msg(
            `${selectedItems.length === 1 ? `„${selectedItems[0]}“` : `${selectedItems.length} Elemente`} gelöscht`,
            null,
            'fal fa-check'
        );
        selection([], true);
    }

    async share() {
        if (noAccess('share')) return;
        const { user, selected } = global;
        const { shareExists } = this.localstate;
        const { shareUsers } = this.localstate.form;
        const folderPath = selected.folders.length === 0 ? getPath() : `${getPath()}${selected.folders[0]}/`;
        const accessList = shareUsers.map(x => ({ name: x.value, access: x.access.value }));
        const sharedItems = await db('GET', `shared`, { owner: user.name }, []);

        global.loading = true;

        if (shareUsers.length > 0) {
            for (const selectedUser of shareUsers) {
                const userShareFolders = sharedItems.filter(x =>
                    x.access.map(y => y.name).includes(selectedUser.value)
                );
                const subFolders = userShareFolders.filter(x => x.path.startsWith(folderPath) && x.path !== folderPath);
                const parentFolders = userShareFolders.filter(
                    x => folderPath.startsWith(x.path) && x.path !== folderPath
                );

                if (parentFolders.length > 0) {
                    msg(
                        `Mit „${selectedUser.value}“ wurde bereits ein übergeordneter Ordner geteilt`,
                        `Ordner: ${parentFolders.map(x => lastPart(x.path)).join(', ')}`,
                        'fal fa-exclamation-circle'
                    );
                    global.loading = false;
                    return;
                }

                for (const subFolder of subFolders) {
                    const updatedAccessList = subFolder.access.filter(x => x.name !== selectedUser.value);
                    const dbAction = updatedAccessList.length > 0 ? 'SET' : 'DELETE';
                    await db(
                        dbAction,
                        `shared`,
                        { path: subFolder.path, owner: user.name },
                        { access: updatedAccessList }
                    );
                }
            }

            if (shareExists) {
                await db(
                    'SET',
                    `shared`,
                    { path: folderPath, owner: user.name },
                    {
                        path: folderPath,
                        owner: user.name,
                        access: accessList,
                    }
                );
            } else {
                await db(
                    'ADD',
                    `shared`,
                    {},
                    {
                        path: folderPath,
                        owner: user.name,
                        access: accessList,
                    }
                );
            }
        } else {
            if (shareExists) await db('DELETE', `shared`, { path: folderPath, owner: user.name }, {});
        }

        update('share');

        this.localstate.form.shareUsers = [];
        this.localstate.shareAccessInfo = null;
        global.loading = false;
        global.modal = null;
        msg('Freigabe aktualisiert', null, 'fal fa-check');
    }

    async onShare() {
        if (noAccess('share')) return;
        const { user, selected, options } = global;
        if (selected.files.length > 0) {
            msg('Es können nur Ordner geteilt werden', 'Wähle nur Ordner aus', 'fal fa-exclamation-circle');
            return;
        }
        if (selected.folders.length > 1) {
            msg('Es kann maximal 1 Ordner gleichzeitig geteilt werden', null, 'fal fa-exclamation-circle');
            return;
        }

        const folderPath = selected.folders.length === 0 ? getPath() : `${getPath()}${selected.folders[0]}/`;
        const result = await db('GET', `shared`, { path: folderPath, owner: user.name }, []);

        this.localstate.form.shareUsers = [];
        this.localstate.shareLink = encrypt(`shared/${user?.name}/${folderPath}`);

        if (result.length > 0) {
            for (const item of result[0].access) {
                this.localstate.form.shareUsers.push({
                    label: item.name === 'link' ? 'Jeder mit Link' : item.name,
                    value: item.name,
                    access: options.shareAccess.find(x => x.value === item.access),
                });
            }
        }

        this.localstate.shareExists = result.length > 0;
        this.localstate.shareAccessInfo = null;
        global.loading = false;
        global.modal = 'share';
    }

    onRename() {
        if (noAccess('rename')) return;
        const { selected } = global;
        const selectedItems = [...selected.folders, ...selected.files];
        const isFile = selected.files.includes(selectedItems[0]);

        this.localstate.form.rename = isFile ? name(selectedItems[0]) : selectedItems[0];
        global.modal = 'rename';
    }

    onCopy(e) {
        if (noAccess('copy')) return;
        const { user, sharedFolder } = global;
        const isTouch = e?.nativeEvent?.sourceCapabilities?.firesTouchEvents;

        global.selected.copymove.action = 'copy';
        global.selected.copymove.userSrc = sharedFolder ? sharedFolder.owner : user?.name;
        global.selected.copymove.src = cleanSharePath(getPath());
        global.selected.copymove.folders = [...global.selected.folders];
        global.selected.copymove.files = [...global.selected.files];

        if (isTouch) selection([], true);
    }

    onMove(e) {
        if (noAccess('move')) return;
        const { user, sharedFolder } = global;
        const isTouch = e?.nativeEvent?.sourceCapabilities?.firesTouchEvents;

        global.selected.copymove.action = 'move';
        global.selected.copymove.userSrc = sharedFolder ? sharedFolder.owner : user?.name;
        global.selected.copymove.src = cleanSharePath(getPath());
        global.selected.copymove.folders = [...global.selected.folders];
        global.selected.copymove.files = [...global.selected.files];

        if (isTouch) selection([], true);
    }

    async onDetails() {
        if (noAccess('details')) return;
        const { selected } = global;
        const selectedItems = [...selected.folders, ...selected.files];

        const result = await action('details', {
            item: selectedItems[0],
        });

        this.localstate.details = result;

        global.modal = 'details';
    }

    render() {
        const {
            host,
            users,
            user,
            options,
            selected,
            loading,
            preview,
            screen,
            selectStyle,
            selectSmallStyle,
            animation,
        } = global;
        const { form, details } = this.localstate;
        const selectedItems = [...selected.folders, ...selected.files];

        return (
            <>
                <AnimatePresence>
                    {(screen.width > 820 || selectedItems.length > 0) && (
                        <motion.div
                            id="actions-container"
                            initial={animation.slide.init}
                            animate={animation.slide.in}
                            exit={animation.slide.out}
                        >
                            <div id="actions">
                                <div id="actions-options">
                                    <button
                                        className="icon"
                                        disabled={!selectedItems.length}
                                        title="Herunterladen"
                                        onClick={() => this.download()}
                                    >
                                        <i className="fal fa-arrow-down-to-line"></i>
                                    </button>
                                    {screen.width > 600 && (
                                        <button
                                            className="icon"
                                            disabled={!lastPart() && selectedItems.length === 0}
                                            title="Teilen"
                                            onClick={() => this.onShare()}
                                        >
                                            <i className="fal fa-share-alt"></i>
                                        </button>
                                    )}
                                    <button
                                        className="icon"
                                        disabled={selectedItems.length !== 1}
                                        title="Umbenennen"
                                        onClick={() => this.onRename()}
                                    >
                                        <i className="fal fa-pen"></i>
                                    </button>
                                    {screen.width > 600 && (
                                        <button
                                            className="icon"
                                            disabled={!selectedItems.length}
                                            title="Kopieren"
                                            onClick={() => this.onCopy()}
                                        >
                                            <i className="fal fa-copy"></i>
                                        </button>
                                    )}
                                    {screen.width > 600 && (
                                        <button
                                            className="icon"
                                            disabled={!selectedItems.length}
                                            title="Verschieben"
                                            onClick={() => this.onMove()}
                                        >
                                            <i className="fal fa-file-export"></i>
                                        </button>
                                    )}
                                    {screen.width > 600 && (
                                        <button
                                            className="icon"
                                            disabled={selectedItems.length !== 1}
                                            title="Details"
                                            onClick={() => this.onDetails()}
                                        >
                                            <i className="fal fa-info-circle"></i>
                                        </button>
                                    )}
                                    <button
                                        className="icon"
                                        disabled={!selectedItems.length}
                                        title="Löschen"
                                        onClick={() => {
                                            if (!noAccess('delete')) global.modal = 'delete';
                                        }}
                                    >
                                        <i className="fal fa-trash"></i>
                                    </button>
                                </div>
                                <div id="actions-selected">
                                    <AnimatePresence>
                                        {selectedItems.length > 0 && (
                                            <motion.div
                                                initial={animation.fade.init}
                                                animate={animation.fade.in}
                                                exit={animation.fade.out}
                                            >
                                                <div>
                                                    <p>{selectedItems.length}</p>
                                                    <span>
                                                        {screen.width <= 900 ? (
                                                            <i className="fad fa-file"></i>
                                                        ) : (
                                                            ' ausgewählt'
                                                        )}
                                                    </span>
                                                </div>
                                                <button
                                                    className="icon"
                                                    disabled={selectedItems.length === 0}
                                                    title="Alle abwählen"
                                                    onClick={() => selection([], true)}
                                                >
                                                    <i className="fal fa-xmark"></i>
                                                </button>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </div>
                                {screen.width > 1200 ? (
                                    <div id="actions-filter">
                                        <Select
                                            options={filterOptions}
                                            value={filterOptions.filter(x => options.filter.props.includes(x.value))}
                                            maxMenuHeight="80vh"
                                            placeholder="Dateien filtern"
                                            isMulti
                                            styles={{
                                                ...selectSmallStyle,
                                                container: baseStyles => ({ ...baseStyles, minWidth: '15em' }),
                                            }}
                                            onChange={select => {
                                                global.options.filter.props = select.map(x => x.value);
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <i className="fal fa-filter" style={{ margin: '0em 0.5em' }}></i>
                                                ),
                                            }}
                                        />
                                        <Select
                                            options={sortingOptions}
                                            value={sortingOptions.find(x => x.value === options.sorting.prop)}
                                            maxMenuHeight="80vh"
                                            placeholder="Sortieren"
                                            isSearchable={false}
                                            styles={selectSmallStyle}
                                            data-sortdir={global.options.sorting.dir}
                                            onChange={select => {
                                                global.options.sorting.prop = select.value;
                                                sortData();
                                            }}
                                            components={{
                                                DropdownIndicator: () => (
                                                    <i
                                                        className={`fal fa-arrow-${
                                                            options.sorting.dir === 'asc' ? 'down' : 'up'
                                                        }-short-wide`}
                                                        style={{ margin: '0em 0.5em', cursor: 'pointer' }}
                                                        onPointerUp={() => {
                                                            global.options.sorting.dir =
                                                                options.sorting.dir === 'asc' ? 'desc' : 'asc';
                                                            sortData();
                                                        }}
                                                    ></i>
                                                ),
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div id="actions-filter">
                                        {screen.width > 600 ? (
                                            <button className="icon" onClick={() => (global.modal = 'filter')}>
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        ) : (
                                            <Popup
                                                trigger={
                                                    <button className="icon">
                                                        <i className="far fa-ellipsis-vertical"></i>
                                                    </button>
                                                }
                                                position="top right"
                                                on="hover"
                                            >
                                                {!preview.item && (
                                                    <>
                                                        <button
                                                            className="inmenu small"
                                                            disabled={
                                                                selectedItems.length ===
                                                                global.folders.length + global.files.length
                                                            }
                                                            onClick={() => {
                                                                global.selected.folders = global.folders.map(
                                                                    x => x.name
                                                                );
                                                                global.selected.files = global.files.map(x => x.name);
                                                            }}
                                                        >
                                                            <i className="fal fa-check-double"></i>
                                                            <span>Alles auswählen</span>
                                                        </button>
                                                        <button
                                                            className="inmenu small"
                                                            onClick={() => (global.modal = 'filter')}
                                                        >
                                                            <i className="fal fa-filter"></i>
                                                            <span>Filtern / Sortieren</span>
                                                        </button>
                                                        <button
                                                            className="inmenu small"
                                                            title="Teilen"
                                                            onClick={() => this.onShare()}
                                                        >
                                                            <i className="fal fa-share-alt"></i>
                                                            <span>Teilen</span>
                                                        </button>
                                                        <button
                                                            className="inmenu small"
                                                            disabled={!selectedItems.length}
                                                            onClick={e => this.onCopy(e)}
                                                        >
                                                            <i className="fal fa-copy"></i>
                                                            <span>Kopieren</span>
                                                        </button>
                                                        <button
                                                            className="inmenu small"
                                                            disabled={!selectedItems.length}
                                                            onClick={e => this.onMove(e)}
                                                        >
                                                            <i className="fal fa-file-export"></i>
                                                            <span>Verschieben</span>
                                                        </button>
                                                    </>
                                                )}
                                                <button
                                                    className="inmenu small"
                                                    disabled={selectedItems.length !== 1}
                                                    onClick={() => this.onDetails()}
                                                >
                                                    <i className="fal fa-info-circle"></i>
                                                    <span>Details</span>
                                                </button>
                                            </Popup>
                                        )}
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <Modal show="createFolder" onClose={() => (this.localstate.form.folderName = '')}>
                    <h1>Neuer Ordner</h1>
                    <label>
                        <p>Name</p>
                        <input
                            type="text"
                            value={form.folderName}
                            maxLength="100"
                            placeholder="Ordnername"
                            disabled={loading}
                            autoFocus
                            onChange={e => (this.localstate.form.folderName = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.createFolder()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.createFolder()}>
                            <i className="fal fa-plus"></i>
                            <span>Erstellen</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="createNote" onClose={() => (this.localstate.form.noteName = '')}>
                    <h1>Neue Notiz</h1>
                    <label>
                        <p>Name</p>
                        <input
                            type="text"
                            value={form.noteName}
                            maxLength="100"
                            placeholder="Notizname"
                            disabled={loading}
                            autoFocus
                            onChange={e => (this.localstate.form.noteName = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.createNote()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.createNote()}>
                            <i className="fal fa-plus"></i>
                            <span>Erstellen</span>
                        </button>
                    </div>
                </Modal>

                <Modal className="modal-share" show="share" onClose={() => selection([], true)}>
                    <h1>Teilen</h1>
                    <h2>
                        <i className="fal fa-folder"></i>
                        {selected.folders[0] || lastPart()}
                    </h2>
                    <button
                        className="small"
                        onClick={() => {
                            window.navigator.clipboard.writeText(`${host}/${this.localstate.shareLink}`);
                            msg('Link kopiert', null, 'fal fa-check');
                        }}
                    >
                        <i className="far fa-link"></i>
                        <span>Link kopieren</span>
                    </button>
                    <label>
                        <p>Teilen mit</p>
                        <Select
                            options={[
                                { label: 'Jeder mit Link', value: 'link', access: options.shareAccess[0] },
                                ...users
                                    .filter(x => x.name !== user?.name)
                                    .map(x => ({ label: x.name, value: x.name, access: options.shareAccess[0] })),
                            ]}
                            value={form.shareUsers}
                            maxMenuHeight={165}
                            placeholder="Nutzer wählen ..."
                            isMulti
                            isDisabled={loading}
                            styles={selectStyle}
                            autoFocus={screen.width > 430}
                            noOptionsMessage={x => x && 'Keine Ergebnisse'}
                            onChange={selected => {
                                this.localstate.form.shareUsers = selected;
                                this.localstate.shareAccessInfo = selected?.length > 0 ? options.shareAccess[0] : null;
                            }}
                        />
                    </label>
                    <div id="share-users">
                        {form.shareUsers.map((x, i) => (
                            <div className="user" key={i}>
                                <p>{x.label}</p>
                                <Select
                                    options={options.shareAccess}
                                    value={x.access}
                                    maxMenuHeight={200}
                                    placeholder="Zugriffsrecht ..."
                                    isSearchable={false}
                                    isDisabled={loading}
                                    styles={selectSmallStyle}
                                    autoFocus={screen.width > 430}
                                    noOptionsMessage={x => x && 'Keine Ergebnisse'}
                                    onChange={selected => {
                                        x.access = selected;
                                        this.localstate.shareAccessInfo = x.access;
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    {this.localstate.shareAccessInfo && (
                        <p className="access-info">
                            <b>{this.localstate.shareAccessInfo.label}:</b>{' '}
                            {this.localstate.shareAccessInfo.description}
                        </p>
                    )}
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.share()}>
                            <i className="fal fa-check"></i>
                            <span>Übernehmen</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="rename" onClose={() => (this.localstate.form.rename = '')}>
                    <h1>Umbenennen</h1>
                    <label>
                        <p>{selectedItems[0]}</p>
                        <input
                            type="text"
                            value={form.rename}
                            maxLength="100"
                            placeholder="Neuer Name"
                            disabled={loading}
                            autoFocus
                            onFocus={e => e.target.select()}
                            onChange={e => (this.localstate.form.rename = e.target.value)}
                            onKeyDown={e => e.key === 'Enter' && this.rename()}
                        />
                    </label>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.rename()}>
                            <i className="fal fa-pen"></i>
                            <span>Umbenennen</span>
                        </button>
                    </div>
                </Modal>

                <Modal
                    show="details"
                    onClose={() => {
                        this.localstate.details = null;
                        selection([], true);
                    }}
                >
                    <h1>Details</h1>
                    <h2 className="center">
                        <i className={fileIcon(selectedItems[0], details?.type)}></i>
                        {selectedItems[0]}
                    </h2>
                    {details ? (
                        <div className="text-grid">
                            {details.type === 'folder' && (
                                <>
                                    <h3>Geteilt mit:</h3>
                                    <p>{details.shared}</p>
                                </>
                            )}
                            <h3>Größe:</h3>
                            <p>{formatBytes(details.size)}</p>
                            <h3>Typ:</h3>
                            <p>{details.type === 'folder' ? 'Ordner' : `Datei (${ext(selectedItems[0])})`}</p>
                            <h3>Zuletzt geändert:</h3>
                            <p>{format(details.dateChange, 'full')}</p>
                            <h3>Zuletzt modifiziert:</h3>
                            <p>{format(details.dateModified, 'full')}</p>
                            <h3>Erstellt:</h3>
                            <p>{format(details.dateCreated, 'full')}</p>
                        </div>
                    ) : (
                        <p>Keine Details verfügbar</p>
                    )}
                </Modal>

                <Modal show="delete">
                    <h1>Löschen</h1>
                    <h2>
                        {selectedItems.length === 1 ? `„${selectedItems[0]}“` : `${selectedItems.length} Elemente`}{' '}
                        wirklich löschen?
                    </h2>
                    <p>
                        Ordnerinhalte werden auch gelöscht.
                        <br />
                        Dieser Schritt ist nicht rückgängig zu machen!
                    </p>
                    <div className="buttons">
                        <button className="primary" disabled={loading} onClick={() => this.delete()}>
                            <i className="fal fa-trash"></i>
                            <span>Dauerhaft löschen</span>
                        </button>
                    </div>
                </Modal>

                <Modal show="filter" className="modal-filter">
                    <h1>Filtern / Sortieren</h1>
                    <label>
                        <p>Dateien filtern</p>
                        <Select
                            options={filterOptions}
                            value={filterOptions.filter(x => options.filter.props.includes(x.value))}
                            maxMenuHeight="30em"
                            placeholder="Dateien filtern"
                            isMulti
                            styles={{
                                ...selectStyle,
                                container: baseStyles => ({ ...baseStyles, minWidth: '15em' }),
                            }}
                            onChange={select => {
                                global.options.filter.props = select.map(x => x.value);
                            }}
                            components={{
                                DropdownIndicator: () => (
                                    <i className="fal fa-filter" style={{ margin: '0em 0.5em' }}></i>
                                ),
                            }}
                        />
                    </label>
                    <label>
                        <p>Sortieren ({options.sorting.dir === 'asc' ? 'Absteigend' : 'Aufsteigend'})</p>
                        <Select
                            options={sortingOptions}
                            value={sortingOptions.find(x => x.value === options.sorting.prop)}
                            maxMenuHeight="80vh"
                            placeholder="Sortieren"
                            styles={selectStyle}
                            data-sortdir={global.options.sorting.dir}
                            onChange={select => {
                                global.options.sorting.prop = select.value;
                                sortData();
                            }}
                            components={{
                                DropdownIndicator: () => (
                                    <i
                                        className={`fal fa-arrow-${
                                            options.sorting.dir === 'asc' ? 'down' : 'up'
                                        }-short-wide`}
                                        style={{ margin: '0em 0.5em', cursor: 'pointer' }}
                                        onPointerUp={() => {
                                            global.options.sorting.dir = options.sorting.dir === 'asc' ? 'desc' : 'asc';
                                            sortData();
                                        }}
                                    ></i>
                                ),
                            }}
                        />
                    </label>
                </Modal>
            </>
        );
    }
}

export default observer(Action);
