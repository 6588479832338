import moment from 'moment';
import CryptoJS from 'crypto-js';

import global from '../components/Global';
import { db, checkHash } from './database';

//############################################################################# Sort Data

function sortData() {
    sortFunction(global.folders, 'name', 'asc');
    sortFunction(global.sharedFolders, 'owner', 'asc');
    sortFunction(global.files);
}

function sortFunction(list = [], sortBy, sortDir) {
    const { dir, prop } = global.options.sorting;
    const dirFactor = sortDir ? (sortDir === 'asc' ? 1 : -1) : dir === 'asc' ? 1 : -1;

    list.sort((a, b) => {
        if (sortBy) {
            if (typeof a[sortBy] === 'string') {
                if (a[sortBy].match(/^\d{4}-\d{2}-\d{2}/))
                    return (new Date(a[sortBy]) - new Date(b[sortBy])) * dirFactor;
                return a[sortBy].toLowerCase().localeCompare(b[sortBy], 'de', { numeric: true }) * dirFactor;
            }
            return (a[sortBy] - b[sortBy]) * dirFactor;
        } else {
            if (typeof a[prop] === 'string') {
                if (a[prop].match(/^\d{4}-\d{2}-\d{2}/)) return (new Date(a[prop]) - new Date(b[prop])) * dirFactor;
                return a[prop].toLowerCase().localeCompare(b[prop], 'de', { numeric: true }) * dirFactor;
            }
            return (a[prop] - b[prop]) * dirFactor;
        }
    });

    return list;
}

//############################################################################# Message

let msgTimeout;

function msg(headline = '', text = '', icon = null) {
    global.msg.headline = headline;
    global.msg.text = text;
    global.msg.icon = icon;
    global.msg.show = true;

    clearTimeout(msgTimeout);
    msgTimeout = setTimeout(hideMsg, 5000);
}

function hideMsg() {
    clearTimeout(msgTimeout);
    global.msg.show = false;
    global.msg.headline = '';
    global.msg.text = '';
    global.msg.icon = null;
}

//############################################################################# Context Menu

function onContextMenu(e) {
    const item = e?.target?.dataset;
    const isTouch = e?.nativeEvent?.sourceCapabilities?.firesTouchEvents;
    if (!e || !item) {
        global.contextmenu.show = false;
        return;
    }

    e.preventDefault();
    if (isTouch) return;

    if (!global.selected[`${item.type}s`].includes(item.name)) {
        if (e.ctrlKey) {
            global.selected[`${item.type}s`].push(item.name);
        } else {
            global.selected[`${item.type}s`] = [item.name];
        }
    }

    const posX = e.nativeEvent.pageX;
    const posY = e.nativeEvent.pageY;

    setTimeout(
        () => {
            const { contextmenu, screen } = global;
            const contextmenuElement = document.getElementById('contextmenu');
            if (contextmenu.x + contextmenuElement.offsetWidth > screen.width)
                global.contextmenu.x = screen.width - contextmenuElement.offsetWidth - 10;
            if (contextmenu.y + contextmenuElement.offsetHeight > screen.height)
                global.contextmenu.y = screen.height - contextmenuElement.offsetHeight - 10;
        },
        global.contextmenu.show ? 1 : 300
    );

    global.contextmenu = {
        show: true,
        basicOptions: item.preview ? true : false,
        x: posX,
        y: posY,
    };
}

//############################################################################# Date Format

function format(str = '', type) {
    switch (type) {
        case 'short':
            str = moment(str).format('DD.MM.YY');
            break;
        case 'full':
            str = moment(str).format('DD.MM.YYYY, HH:mm');
            break;
        case 'fullShort':
            str = moment(str).format('DD.MM.YY, HH:mm');
            break;
        case 'time':
            str = moment(str).format('HH:mm:ss');
            break;
        case 'timeShort':
            str = moment(str).format('HH:mm');
            break;
        case 'text':
            str = moment(str).format('LL');
            break;
        default:
            str = moment(str).format('DD.MM.YYYY');
            break;
    }
    return str;
}

//############################################################################# Format Bytes

function formatBytes(bytes = 0, decimals = 2) {
    if (typeof bytes == 'string') bytes = parseInt(bytes);
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, index)).toFixed(decimals))} ${sizes[index]}`.replace('.', ',');
}

//############################################################################# Random ID

function randomId(idLength = 10, allChars) {
    const chars = allChars
        ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!-#$0123456789'
        : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let id = '';

    for (let i = 0; i < idLength; i++) {
        const randomNumber = Math.floor(Math.random() * (chars.length - 1));
        id += chars.charAt(randomNumber);
    }

    return id;
}

//############################################################################# Encrypt / Decrypt

const aesKey = CryptoJS.enc.Utf8.parse('GFiJLfwFHxXKXycZKheeRPdTTEKaqOlE');
const aesIv = CryptoJS.enc.Utf8.parse('0123456789abcdefghijklmnopqrstuvwxyz');

const aesOptions = {
    iv: aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

function encrypt(str = '') {
    return CryptoJS.AES.encrypt(str, aesKey, aesOptions).ciphertext.toString();
}

function decrypt(str = '') {
    const encoded = { ciphertext: CryptoJS.enc.Hex.parse(str) };
    return CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encoded, aesKey, aesOptions));
}

//############################################################################# File Icon

function fileIcon(fileName = '', type) {
    if (typeof fileName !== 'string') fileName = '';
    if (type === 'folder') return 'fal fa-folder';

    const { extension, extIcons } = global;
    const fileExt = ext(fileName) || '';

    for (const key in extension) {
        const foundExt = extension[key].find(x => x === fileExt);
        if (foundExt) return extIcons[key];
    }

    return 'fas fa-file';
}

//############################################################################# Helper Functions

function download(path = '', name = lastPart(path)) {
    const { host } = global;
    let link = document.createElement('a');

    link.setAttribute('download', name);
    link.href = path.replace(host, '');
    document.body.appendChild(link);

    link.setAttribute('target', '_blank');
    link.click();
    link.remove();
}

function focus(ref) {
    setTimeout(() => {
        ref?.current?.focus();
    }, 50);
}

function name(str = '') {
    return str.replace(/(.+?)(\.[^.]*$|$)/, '$1');
}

function ext(str = '', removeDot = true) {
    let output = str.replace(/(.+?)(\.[^.]*$|$)/, '$2');
    if (output.length === 0) output = name(str);
    if (removeDot) output = output.replace('.', '');
    return output;
}

function lastPart(str = getPath()) {
    const match = str.match(/[^/]+(?=\/$|$)/);
    return match ? match[0] : '';
}

function getPath(original = false) {
    const url = cleanPath(window.location.pathname);
    const path = original ? url : cleanPath(decrypt(url));
    return path === '' ? path : `${path}/`;
}

function cleanPath(path = '') {
    return path.replace(/\/{2,}/g, '/').replace(/^\/+|\/+$/, '');
}

function cleanSharePath(path = '') {
    const { sharedFolder } = global;
    return sharedFolder ? path.replace(`shared/${sharedFolder.owner}/`, '') : path;
}

async function installApp() {
    const { event } = global.installapp;
    event.prompt();
    await event.userChoice;
}

function noAccess(action, userAccess) {
    const { sharedFolder } = global;
    let hasAccess = false;

    const permission = {
        viewer: ['download', 'details'],
        visitor: ['download', 'upload', 'createFolder', 'createNote', 'rename', 'copy', 'details', 'contentEditing'],
        admin: [
            'download',
            'upload',
            'createFolder',
            'createNote',
            'share',
            'rename',
            'copy',
            'move',
            'details',
            'contentEditing',
            'delete',
        ],
    };

    if (userAccess) {
        if (permission[userAccess].includes(action)) hasAccess = true;
    } else {
        if (!sharedFolder || permission[sharedFolder.access].includes(action)) hasAccess = true;
    }

    if (!hasAccess) msg('Du hast keine Berechtigung dazu', null, 'fal fa-shield-xmark');

    return !hasAccess;
}

function findAccess(accessList = [], userName = null) {
    const user = userName ? userName : global.user?.name || null;
    const foundUser = accessList.find(x => x.name === user);

    if (!user || !foundUser) {
        return accessList.find(x => x.name === 'link')?.access || null;
    } else {
        return foundUser?.access || null;
    }
}

//############################################################################# Export

export {
    db,
    checkHash,
    encrypt,
    decrypt,
    sortData,
    msg,
    hideMsg,
    onContextMenu,
    randomId,
    focus,
    format,
    name,
    ext,
    lastPart,
    getPath,
    cleanPath,
    cleanSharePath,
    fileIcon,
    noAccess,
    findAccess,
    formatBytes,
    download,
    sortFunction,
    installApp,
};
