import { makeAutoObservable, configure } from 'mobx';

import { cleanSharePath, getPath, noAccess, ext } from '../js/functions';
import { changePreviewItem, copyMove, selection } from '../js/helper';

configure({
    enforceActions: 'never',
});

class global {
    url =
        process.env.NODE_ENV === 'production'
            ? 'https://dev.mydatasafe.de/server/src'
            : 'http://127.0.0.1/Projects/mydatasafe/server/src';

    host =
        process.env.NODE_ENV === 'production'
            ? 'https://dev.mydatasafe.de'
            : 'http://127.0.0.1/Projects/mydatasafe/demo';

    path =
        process.env.NODE_ENV === 'production'
            ? 'https://dev.mydatasafe.de'
            : 'http://127.0.0.1/Projects/mydatasafe/demo/user/';

    users = [];
    user = null;
    modal = null;
    sharedFolder = null;
    editor = null;
    sharePathRemove = '';
    folders = [];
    files = [];
    sharedFolders = [];
    uploads = [];
    downloads = [];
    loading = false;
    startup = true;
    error404 = false;

    screen = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    preview = {
        item: null,
        lastIitem: null,
        action: null,
        sidebar: true,
        noteModified: 0,
    };

    drag = {
        timeout: null,
        init: false,
        active: false,
        start: { name: '', type: '' },
        userDest: null,
        dest: null,
        pos: { x: 0, y: 0 },
    };

    selected = {
        folders: [],
        files: [],
        copymove: {
            action: 'move',
            src: '',
            userSrc: '',
            folders: [],
            files: [],
        },
        selection: null,
    };

    contextmenu = {
        show: false,
        x: 0,
        y: 0,
    };

    options = {
        filter: {
            props: [],
        },
        sorting: {
            prop: 'date',
            dir: 'desc',
        },
        shareAccess: [
            { label: 'Betrachter', value: 'viewer', description: 'Inhalte ansehen & herunterladen' },
            { label: 'Besucher', value: 'visitor', description: 'Upload, Download & Umbenennen' },
            { label: 'Admin', value: 'admin', description: 'Vollzugriff, keine Einschränkung' },
        ],
    };

    msg = {
        show: false,
        headline: '',
        text: '',
        icon: null,
    };

    keyPressed = {
        ctrl: false,
        shift: false,
        alt: false,
    };

    installapp = {
        show: false,
        event: null,
    };

    animation = {
        fade: {
            init: {
                opacity: 0,
            },
            in: {
                opacity: 1,
                transition: {
                    opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
                },
            },
            out: {
                opacity: 0,
                transition: {
                    opacity: { ease: [0.65, 0, 0.35, 1], duration: 0.3 },
                },
            },
        },
        slide: {
            init: {
                opacity: 0,
                height: 0,
            },
            in: {
                opacity: 1,
                height: 'auto',
                transition: {
                    opacity: { ease: [0.3, 0.93, 0.35, 1], duration: 0.5 },
                    height: { ease: [0.3, 0.93, 0.35, 1], duration: 0.3 },
                },
            },
            out: {
                opacity: 0,
                height: 0,
            },
        },
    };

    constructor() {
        makeAutoObservable(this);
    }
}

const data = new global();
// const data = window.data = new global();

//############################################################################# Events

const allowKey = ['INPUT', 'TEXTAREA'];

document.addEventListener('keydown', e => {
    if (e.repeat) return;
    const inText = allowKey.includes(e.target.tagName) || data.modal;

    data.keyPressed.ctrl = e.ctrlKey;
    data.keyPressed.shift = e.shiftKey;
    data.keyPressed.alt = e.altKey;

    if (e.key === 'Control') {
        if (data.drag.active && e.ctrlKey && data.drag.dest === null) data.drag.dest = cleanSharePath(getPath());
    }

    if (e.ctrlKey && !data.preview.item) {
        if (e.key === 'a') {
            if (inText) return;
            e.preventDefault();
            data.selected.folders = data.folders.map(x => x.name);
            data.selected.files = data.files.map(x => x.name);
        }
        if (e.key === 'c') {
            if (inText) return;
            e.preventDefault();
            if (noAccess('copy')) return;
            const { user, sharedFolder } = data;
            data.selected.copymove.action = 'copy';
            data.selected.copymove.userSrc = sharedFolder ? sharedFolder.owner : user?.name;
            data.selected.copymove.src = cleanSharePath(getPath());
            data.selected.copymove.folders = [...data.selected.folders];
            data.selected.copymove.files = [...data.selected.files];
        }
        if (e.key === 'x') {
            if (inText) return;
            e.preventDefault();
            if (noAccess('move')) return;
            const { user, sharedFolder } = data;
            data.selected.copymove.action = 'move';
            data.selected.copymove.userSrc = sharedFolder ? sharedFolder.owner : user?.name;
            data.selected.copymove.src = cleanSharePath(getPath());
            data.selected.copymove.folders = [...data.selected.folders];
            data.selected.copymove.files = [...data.selected.files];
        }
        if (e.key === 'v') {
            if (data.selected.copymove.folders.length > 0 || data.selected.copymove.files.length > 0) {
                if (inText) return;
                e.preventDefault();
                if (noAccess('upload')) return;
                copyMove();
            }
        }
    }

    if (e.key === 'Escape') {
        if (data.preview.item && !data.modal) {
            if (data.preview.noteModified >= 5) {
                const confirmation = window.confirm(
                    'Deine Änderungen werden eventuell nicht gespeichert.\nHast du bereits gespeichert, kannst du fortfahren.\n\nMöchtest du fortfahren?'
                );
                if (!confirmation) return;
            }
            data.preview.item = null;
        }
        data.modal = null;
        data.contextmenu.show = false;
        data.selected.copymove.folders = [];
        data.selected.copymove.files = [];
        selection([], true);
    }

    if (e.key === 'Delete') {
        if (!inText && (data.selected.folders.length > 0 || data.selected.files.length > 0)) {
            e.preventDefault();
            if (!noAccess('delete')) data.modal = 'delete';
        }
    }

    if (e.key === 'F2') {
        if (!inText && (data.selected.folders.length > 0 || data.selected.files.length > 0)) {
            e.preventDefault();
            if (!noAccess('rename')) data.modal = 'rename';
        }
    }

    if (data.preview.item) {
        const fileExt = ext(data.preview.item?.name) || '';
        if (fileExt !== 'txt' && !fileTypes.code.includes(fileExt)) {
            if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') changePreviewItem('prev');
            if (e.key === 'ArrowDown' || e.key === 'ArrowRight') changePreviewItem('next');
        }
    }
});

document.addEventListener('keyup', e => {
    data.keyPressed.ctrl = e.ctrlKey;
    data.keyPressed.shift = e.shiftKey;
    data.keyPressed.alt = e.altKey;

    if (e.key === 'Control') {
        if (data.drag.active && data.drag.dest === cleanSharePath(getPath())) data.drag.dest = null;
    }
});

window.addEventListener('resize', () => {
    data.screen.width = window.innerWidth;
    data.screen.height = window.innerHeight;
    if (data.screen.width <= 1024) {
        data.preview.sidebar = false;
    } else {
        data.preview.sidebar = true;
    }
});

window.addEventListener('popstate', e => {
    if (!data.preview.item) return;
    e.preventDefault();
});

window.addEventListener('beforeunload', e => {
    // if (data.preview.item) {
    //     const fileExt = ext(data.preview.item?.name) || "";
    //     if (fileExt === "txt" || fileTypes.code.includes(fileExt)) e.returnValue = true;
    // }
});

window.addEventListener('beforeinstallprompt', async e => {
    e.preventDefault();

    data.installapp.show = true;
    data.installapp.event = e;
});

//############################################################################# Extensions

const extension = {
    image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'giff', 'tiff', 'tif', 'ico', 'tga', 'webp', 'raw'],
    video: [
        'mp4',
        'avi',
        'mkv',
        'mov',
        'flv',
        'wmv',
        '3gp',
        'mpg',
        'mpeg',
        'mpeg4',
        'vob',
        'swf',
        'webm',
        'vob',
        'mts',
    ],
    audio: ['mp3', 'wav', 'ogg', 'wma', 'aac', 'flac', 'ac3', 'm4a', 'amr', 'mmf', 'm4r', 'mp2', 'cda', 'mpa', 'aif'],
    word: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
    pp: ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'sldx', 'sldm'],
    excel: ['csv', 'xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'],
    pdf: ['pdf'],
    text: ['txt', 'odt', 'rtf', 'tex', 'wks', 'wps', 'wpd'],
    html: ['html', 'htm'],
    css: ['css'],
    sass: ['sass', 'scss', 'less'],
    js: ['js', 'jsx'],
    php: ['php'],
    python: ['py'],
    compress: ['zip', 'rar', '7z', 'arj', 'deb', 'pkg', 'rpm', 'tar', 'z', 'tgz'],
    disc: ['bin', 'dmg', 'iso', 'toast', 'vcd'],
    font: ['ttf', 'otf', 'fnt', 'fon', 'cef', 'scr', 'pk', 'pfm', 'pcm', 'pxl', 'apf', 'asf', 'chm', 'fli'],
    shortcut: ['lnk', 'shortcut'],
    app: ['exe'],
    code: [
        'bat',
        'vbs',
        'xml',
        'json',
        'ini',
        'lua',
        'con',
        'fs',
        'vs',
        'gs',
        'dll',
        'info',
        'ress',
        'vdf',
        'acf',
        'java',
        'cc',
        'cpp',
        'htaccess',
    ],
    edit: ['eps', 'ai', 'ps', 'psd', 'afphoto', 'indd', 'swf', 'svg'],
    midi: ['midi', 'mid'],
    imageThumb: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
    videoThumb: ['mp4', 'avi', 'mov', 'flv', 'wmv', 'mpg', 'mpeg', 'mpeg4'],
    all: [],
};

const fileTypes = {
    image: extension.image,
    video: extension.video,
    audio: extension.audio,
    document: [
        ...extension.word,
        ...extension.pp,
        ...extension.excel,
        ...extension.pdf,
        ...extension.edit,
        ...extension.text,
    ],
    code: [
        ...extension.code,
        ...extension.html,
        ...extension.css,
        ...extension.sass,
        ...extension.css,
        ...extension.js,
        ...extension.php,
        ...extension.python,
    ],
    other: [
        ...extension.compress,
        ...extension.disc,
        ...extension.font,
        ...extension.shortcut,
        ...extension.app,
        ...extension.midi,
    ],
};

const fileTypesLabel = {
    image: 'Bilder',
    video: 'Videos',
    document: 'Dokumente',
    audio: 'Musik',
    code: 'Code',
    other: 'Andere',
};

for (const key in extension) extension.all.push(...extension[key]);

const extIcons = {
    videoThumb: 'fas fa-play',
    file: 'fas fa-file',
    image: 'fas fa-image',
    audio: 'fas fa-music',
    video: 'fas fa-film',
    word: 'fas fa-file-word',
    pp: 'fas fa-file-powerpoint',
    excel: 'fas fa-file-csv',
    pdf: 'fas fa-file-pdf',
    html: 'fas fa-code',
    css: 'fab fa-css3-alt',
    sass: 'fab fa-sass',
    js: 'fab fa-js-square',
    php: 'fab fa-php',
    python: 'fab fa-python',
    compress: 'fas fa-file-archive',
    text: 'fas fa-file-alt',
    disc: 'fas fa-compact-disc',
    font: 'fas fa-font-case',
    shortcut: 'fas fa-external-link',
    app: 'fas fa-browser',
    code: 'fas fa-brackets-curly',
    edit: 'fas fa-bezier-curve',
    midi: 'fas fa-piano-keyboard',
};

data.extension = extension;
data.fileTypes = fileTypes;
data.fileTypesLabel = fileTypesLabel;
data.extIcons = extIcons;

//############################################################################# Select Style

data.selectStyle = {
    container: (baseStyles, state) => ({
        ...baseStyles,
        width: '100%',
        outline: 'none',
        opacity: state.isDisabled ? 0.4 : 1,
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: 'auto',
        fontSize: '0.9em',
        backgroundColor: 'var(--color_dark)',
        borderColor: state.isFocused ? 'var(--color_theme_dark)' : 'var(--color_gray2)',
        borderWidth: '2px',
        padding: '0.25em 0.4em',
        outline: 'none',
        borderRadius: '0.4em',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'var(--color_theme_dark)' : 'var(--color_gray3)',
        },
    }),
    input: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
    }),
    menu: baseStyles => ({
        ...baseStyles,
        backgroundColor: 'var(--color_gray)',
        borderRadius: '0.4em',
        zIndex: 3,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: '0.85em',
        backgroundColor: state.isSelected
            ? 'var(--color_theme_dark)'
            : state.isFocused
            ? 'var(--color_gray2)'
            : 'transparent',
        borderRadius: '0.4em',
        '&:active': {
            backgroundColor: state.isSelected ? 'var(--color_theme_dark)' : 'var(--color_gray3)',
        },
    }),
    singleValue: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        fontSize: '0.9em',
        backgroundColor: 'var(--color_theme_dark)',
        borderRadius: '0.4em',
        padding: '0.1em 0.2em',
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
    }),
    placeholder: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_gray3)',
    }),
    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        backgroundColor: 'var(--color_gray2)',
    }),
    dropdownIndicator: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
        '&:hover': {
            color: 'var(--color_bright)',
        },
    }),
    clearIndicator: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_gray3)',
        '&:hover': {
            color: 'var(--color_bright)',
        },
    }),
};

data.selectSmallStyle = {
    container: (baseStyles, state) => ({
        ...baseStyles,
        width: '9em',
        maxWidth: '100%',
        outline: 'none',
        opacity: state.isDisabled ? 0.4 : 1,
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: 'auto',
        fontSize: '0.9em',
        backgroundColor: 'var(--color_dark2)',
        borderColor: state.isFocused ? 'var(--color_theme_dark)' : 'var(--color_gray2)',
        borderWidth: '2px',
        padding: '0em 0.5em',
        outline: 'none',
        borderRadius: '10em',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'var(--color_theme_dark)' : 'var(--color_gray3)',
        },
    }),
    input: baseStyles => ({
        ...baseStyles,
        fontSize: '0.9em',
        color: 'var(--color_bright)',
    }),
    menu: baseStyles => ({
        ...baseStyles,
        backgroundColor: 'var(--color_gray)',
        borderRadius: '0.4em',
        zIndex: 3,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: '0.8em',
        backgroundColor: state.isSelected
            ? 'var(--color_theme_dark)'
            : state.isFocused
            ? 'var(--color_gray2)'
            : 'transparent',
        borderRadius: '0.4em',
        '&:active': {
            backgroundColor: state.isSelected ? 'var(--color_theme_dark)' : 'var(--color_gray3)',
        },
    }),
    singleValue: baseStyles => ({
        ...baseStyles,
        fontSize: '0.9em',
        color: 'var(--color_bright)',
        padding: '0.3em 0em',
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        fontSize: '0.9em',
        backgroundColor: 'var(--color_theme_dark)',
        borderRadius: '0.2em',
        padding: '0em 0.1em',
    }),
    multiValueLabel: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
    }),
    placeholder: baseStyles => ({
        ...baseStyles,
        fontSize: '0.9em',
        color: 'var(--color_gray3)',
    }),
    indicatorsContainer: baseStyles => ({
        ...baseStyles,
    }),
    indicatorSeparator: baseStyles => ({
        ...baseStyles,
        backgroundColor: 'var(--color_gray2)',
    }),
    dropdownIndicator: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_bright)',
        '&:hover': {
            color: 'var(--color_bright)',
        },
    }),
    clearIndicator: baseStyles => ({
        ...baseStyles,
        color: 'var(--color_gray3)',
        '&:hover': {
            color: 'var(--color_bright)',
        },
    }),
};

export default data;
